import React, { useState } from "react";
import { Outlet, NavLink, Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import styles from "./Layout.module.css";
import { useAuth } from "../../authContext";
import { Avatar } from "@fluentui/react-components";
import { ContextualMenu, DirectionalHint, IContextualMenuItem } from "@fluentui/react";
import { Toaster } from "@/components/ui/toaster";

const Layout = () => {
    const { session, user, loading } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const [target, setTarget] = useState<HTMLElement | null>(null);

    const menuItems: IContextualMenuItem[] = [
        {
            key: "profileSettings",
            text: "Profile Settings",
            iconProps: { iconName: "Settings", style: { color: "#222222" } },
            onClick: () => {
                navigate("/profile-settings");
            }
        },
        {
            key: "logout",
            text: "Logout",
            iconProps: { iconName: "SignOut", style: { color: "#222222" } },
            onClick: () => {
                handleSignOut();
            }
        }
    ];

    const handleSignOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error("Error signing out:", error);
        } else {
            navigate("/login");
        }
    };

    if (!loading && !session) {
        // console.log("No session found, redirecting to login page");
        return <Navigate to="/login" state={{ from: location }} />;
    }

    const onAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setTarget(event.currentTarget);
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Bible Chat App</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Chat
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/qa" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Ask a question
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                    <div className="flex items-center">
                        <Avatar
                            className="cursor-pointer bg-white text-black rounded-full"
                            onClick={onAvatarClick}
                            image={{ src: user?.user_metadata.avatar_url ?? "" }}
                            name={user?.email ?? "User"}
                            size={32}
                        />
                        {isMenuOpen && (
                            <ContextualMenu
                                items={menuItems}
                                target={target}
                                onDismiss={() => setIsMenuOpen(false)}
                                directionalHint={DirectionalHint.bottomRightEdge}
                                className="bg-white text-black shadow-lg"
                            />
                        )}
                    </div>
                </div>
            </header>
            <Outlet />
            <Toaster />
        </div>
    );
};

export default Layout;
