import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { AuthProvider } from "./authContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

initializeIcons();

const queryClient = new QueryClient();

const router = createHashRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "qa",
                lazy: () => import("./pages/ask/Ask")
            },
            {
                path: "profile-settings",
                lazy: () => import("./pages/profile-settings/ProfileSettings")
            },
            {
                path: "file-manager",
                lazy: () => import("./pages/file-manager/FileManager")
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    },
    {
        path: "login",
        lazy: () => import("./pages/login/Login")
    },
    {
        path: "success",
        lazy: () => import("./pages/success/ConfirmationSuccess")
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <ToastContainer />
            </QueryClientProvider>
        </AuthProvider>
    </React.StrictMode>
);
